export const Bio = {
  name: "Ronin",
  roles: [
    "Full Stack Developer",
    "Programmer",
  ],
  description:
    "Just a civil engineer with curious mind.",
  github: "https://github.com/",
  resume:
    "Not ready yet >",
  linkedin: "https://www.linkedin.com/",
  twitter: "https://twitter.com/",
  insta: "https://www.instagram.com/nxt_7r",
  facebook: "https://www.facebook.com/",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
/*       {
        name: "React Js",
        image:
          "",
      },
      {
        name: "Next Js",
        image:
          "",
      }, */
      {
        name: "HTML",
        image: "https://i.pinimg.com/236x/ca/e1/b4/cae1b4f6b223fe5a7bb712b680cffa67.jpg",
      },
      {
        name: "CSS",
        image:
          "https://i.pinimg.com/236x/17/65/0d/17650db87fb5752364a9a4b6885290ac.jpg",
      },
      {
        name: "JavaScript",
        image:
          "https://i.pinimg.com/236x/7b/56/8e/7b568e01183f783a02fa62c7691e3d84.jpg",
      },


    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Node Js",
        image: "https://i.pinimg.com/236x/f3/4a/02/f34a0218dd2e38726c52ac763df55fa7.jpg",
      },
 /*      {

        name: "Python",
        image:
          "",
      },
      {
        name: "MySQL",
        image:
          "",
      },
      {
        name: "Postgresql",
        image: "",
      }, */
      {
        name: "MongoDB",
        image:
          "https://i.pinimg.com/236x/69/78/3a/69783ad8d0e5f05042e2dbb58860cd83.jpg",
      },
      {
        name: "Firebase",
        image: "https://i.pinimg.com/236x/2c/67/34/2c6734517da129fd3f8f053b49facc43.jpg",
      },
    ],
  },
/*   {
    title: "Android",
    skills: [
      {
        name: "Java",
        image:
          "",
      },
      {
        name: "Kotlin",
        image:
          "",
      },
      {
        name: "XML",
        image:
          "",
      },
      {
        name: "Android Studio",
        image:
          "",
      },
    ],
  }, */
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://i.pinimg.com/236x/50/9d/08/509d08cc38dea759112858bc6d85a936.jpg",
      },
      {
        name: "GitHub",
        image:
          "https://i.pinimg.com/236x/64/88/0b/64880b9b0fe5b53bbe3f7280d262b33f.jpg",
      },
      {
        name: "Docker",
        image:
          "https://i.pinimg.com/236x/0b/a1/a4/0ba1a49d4ed0e18b70faf8e457135cd6.jpg",
      },
      {
        name: "VS Code",
        image:
          "https://i.pinimg.com/236x/76/42/c0/7642c0f576da8830ae2c400e08f2407a.jpg",
      },
      {
        name: "Adobe",
        image:
          "https://i.pinimg.com/236x/e5/45/6b/e5456bfa552e1de39c0cf0d247a8538d.jpg",
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "",
    role: "Devloper/Owner",
    company: "Twilight",
    date: "13/12/2023",
    desc: "A Whatsapp bots community",
    skills: [
      "JS ESM/CJS",
      "MongoDb",
      "LocalDb",
    ],
    doc: "",
  },
 /*  {
    id: 0,
    img: "",
    role: "Your role",
    company: "Your company name",
    date: "Date",
    desc: "Description",
    skills: [
      "skill 1",
      "skill 2",
      "skill 3",
      "skill 4",
      "skill 5",
    ],
    doc: "",
  },
  {
    id: 0,
    img: "",
    role: "Your role",
    company: "Your company name",
    date: "Date",
    desc: "Description",
    skills: [
      "skill 1",
      "skill 2",
      "skill 3",
      "skill 4",
      "skill 5",
    ],
    doc: "",
  },
  {
    id: 0,
    img: "",
    role: "Your role",
    company: "Your company name",
    date: "Date",
    desc: "Description",
    skills: [
      "skill 1",
      "skill 2",
      "skill 3",
      "skill 4",
      "skill 5",
    ],
    doc: "",
  },
  {
    id: 0,
    img: "",
    role: "Your role",
    company: "Your company name",
    date: "Date",
    desc: "Description",
    skills: [
      "skill 1",
      "skill 2",
      "skill 3",
      "skill 4",
      "skill 5",
    ],
    doc: "",
  },
  {
    id: 0,
    img: "",
    role: "Your role",
    company: "Your company name",
    date: "Date",
    desc: "Description",
    skills: [
      "skill 1",
      "skill 2",
      "skill 3",
      "skill 4",
      "skill 5",
    ],
    doc: "",
  }, */
];

export const education = [
  {
    id: 0,
    img: "https://images.shiksha.com/mediadata/images/1679388540phpeTBdC2.jpeg",
    school: "RSCOE",
    date: "date",
    grade: "Diploma in Civil Engg.",
    desc: "Description",
    degree: "Diploma in Civil Engg.",
  },
/*   {
    id: 1,
    img: "",
    school: "Your schoool",
    date: "date",
    grade: "Your grade",
    desc: "Description",
    degree: "Your degree",
  },
  {
    id: 2,
    img: "",
    school: "Your schoool",
    date: "date",
    grade: "Your grade",
    desc: "Description",
    degree: "Your degree",
  }, */
];

export const projects = [
  {
    id: 9,
    title: "Zero-Two",
    date: "project date",
    description:
      "A normal Bot with 10K users",
    image:
      "https://i.pinimg.com/236x/d1/b8/4d/d1b84d734786a38c5565b47aeb5e69dd.jpg",
    tags: [
      "Tags",
    ],
    category: "Private",
    github: "Private",
    webapp: "https://chat.whatsapp.com/CDi26omRonACmxDS5BCbrP",
  },
  {
    id: 0,
    title: "Twilight-web",
    date: "project date",
    description:
      "Web-Site for Twilight",
    image:
      "https://i.pinimg.com/564x/6d/25/bc/6d25bc8c90c7506f3f4ddd0ba09b1ece.jpg",
  
    tags: [
      "Tags",
    ],
    category: "Private",
  
    github: "github link",
    webapp: "https://web-twi-02.onrender.com/",
  },
 /*  {
    id: 1,
    title: "Project title",
    date: "project date",
    description:
      "Description",
    image:
      "",
    tags: [
      "Tags",
    ],
    category: "project category",
    github: "github link",
    webapp: "app link",
  },
  {
    id: 2,
    title: "Project title",
    date: "project date",
    description:
      "Description",
    image:
      "",
    tags: [
      "Tags",
    ],
    category: "project category",
    github: "github link",
    webapp: "app link",
  },
  {
    id: 3,
    title: "Project title",
    date: "project date",
    description:
      "Description",
    image:
      "",
    tags: [
      "Tags",
    ],
    category: "project category",
    github: "github link",
    webapp: "app link",
  },
  {
    id: 10,
    title: "Project title",
    date: "project date",
    description:
      "Description",
    image:
      "",
    tags: [
      "Tags",
    ],
    category: "project category",
    github: "github link",
    webapp: "app link",
  },
  {
    id: 4,
    title: "Project title",
    date: "project date",
    description:
      "Description",
    image:
      "",
    tags: [
      "Tags",
    ],
    category: "project category",
    github: "github link",
    webapp: "app link",
  },
  {
    id: 5,
    title: "Project title",
    date: "project date",
    description:
      "Description",
    image:
      "",
    tags: [
      "Tags",
    ],
    category: "project category",
    github: "github link",
    webapp: "app link",
  },
  {
    id: 6,
    title: "Project title",
    date: "project date",
    description:
      "Description",
    image:
      "",
    tags: [
      "Tags",
    ],
    category: "project category",
    github: "github link",
    webapp: "app link",
  },
  {
    id: 7,
    title: "Project title",
    date: "project date",
    description:
      "Description",
    image:
      "",
    tags: [
      "Tags",
    ],
    category: "project category",
    github: "github link",
    webapp: "app link",
  },
  {
    id: 8,
    title: "Project title",
    date: "project date",
    description:
      "Description",
    image:
      "",
    tags: [
      "Tags",
    ],
    category: "project category",
    github: "github link",
    webapp: "app link",
  }, */
];

export const TimeLineData = [
/*   { year: 2017, text: "first experience" },
  { year: 2018, text: "second experience" },
  { year: 2019, text: "third experience" },
  { year: 2020, text: "fourth experience" },
  { year: 2021, text: "fifth experience" }, */
  /* { year: 2022, text: "fifth experience" }, */
  { year: 2023, text: "First experience" },
];
